<template>
  <div>
    <b-form-input
      v-model="search"
      type="search"
      :placeholder="placeholder"
      size="sm"
      autocomplete="off"
      class="form-control--grey"
      :state="checkCombobox"
      @input="getItemsFilters"
      @click="openAutoComplete = true"
      :disabled="disabled"
    ></b-form-input>

    <div
      v-if="itemsFilter.length > 0 && openAutoComplete"
      class="w-100 position-relative"
      style="z-index: 50;"
    >
      <ul
        class="list-group list-group-flush pl-0 overflow-auto position-absolute w-100 shadow"
        style="max-height: 200px;"
      >
        <li
          v-for="(item, index) in itemsFilter"
          :key="`option_${index}`"
          @click="selectElement(item)"
          class="list-group-item list-group-item-action"
          style="cursor: pointer;"
        >
          {{ item[fieldName] }}
        </li>
      </ul>
    </div>

    <b-form-invalid-feedback :state="checkCombobox">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    fieldName: {
      type: String,
      required: false,
      default: "name"
    },
    itemSelected: {
      type: Object,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    },
    isSearchBack: {
      type: Boolean,
      required: false
    },
    isSubmit: {
      type: Boolean,
      required: false
    },
    fieldToCheck: {
      type: String,
      required: false,
      default: "id"
    },
    isCanCheck: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      search: "",
      openAutoComplete: false,
      itemsFilter: [],
      timeOutSearch: {}
    };
  },

  beforeMount() {
    this.search = this.value;
  },

  computed: {
    checkCombobox() {
      const isCheck = this.itemSelected[this.fieldToCheck] !== undefined;
      return this.isCanCheck ? isCheck : null;
    }
  },

  methods: {
    selectElement(item) {
      this.search = item.name;
      this.$emit("selectElement", item);
      this.openAutoComplete = false;
    },

    getItemsFilters() {
      if (this.isSearchBack) {
        clearTimeout(this.timeOutSearch);
        this.timeOutSearch = setTimeout(() => {
          this.$emit("loadItems", {
            page: 1,
            search: this.search.toLocaleLowerCase()
          });
        }, 500);
      } else {
        let fitlerItems = [...this.items];

        if (this.search.length > 0) {
          fitlerItems = this.items.filter(element => {
            return element[this.fieldName]
              .toLowerCase()
              .includes(this.search.toLowerCase());
          });
        }

        this.itemsFilter = fitlerItems;
      }
    }
  },

  watch: {
    value() {
      this.search = this.value;
    },

    items() {
      this.itemsFilter = [...this.items];
    }
  }
};
</script>
